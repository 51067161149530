
import React, { useState } from 'react';

// redux
import { useDispatch } from 'react-redux';
import globalErrorsActions from "../../../../services/redux/actions/globalErrors.action";
import userActions from "../../../../services/redux/actions/user.action";

// cookies
import Cookies from 'universal-cookie';

// networking
import authApi from "../../../../services/Networking/authentication";

// local service
import emailErrors from "./services/errors/email";
import passwordErrors from "./services/errors/password";
import authUserErrors from "./services/errors/authUser";
import resetErrors from "./services/errors/reset";

// services
import convertError from "../../../../services/errors/convertError";
import validateEmail from "../services/validate/email"
import validatePassword from "./services/validate/loginPassword";

// constants
import { INTERNAL_ERROR } from "../../../../services/errors/constants";

// classes
import loginInfo from "./services/classes";
import errorClass from "../../../../services/errors/classes";

// style
import "./Login.scss";

// components
import TextInput from '../../../../components/input/Text';
import PasswordInput from "../../../../components/input/Password";
import Submit from "../../../../components/input/elements/Submit";
import Loader from "../../../../components/Loader";

// elements
import Title from "../../../../elements/ModalTitle";

// constants
import { SUCCESS, UNAUTHORIZED, NOT_FOUND, NO_CONTENT } from "../../../../services/errors/constants";
import handleEnvironment from '../../../../services/handleEnvironment/handleEnvironment';
import { UVA_ENV } from '../../../../services/handleEnvironment/constants';
import adminView from '../../../../services/uva/adminView';

const Login = (props) => {

    const externalLoginUrl = window.REACT_APP_OIDC_EXT_LOGIN_URL+"?provider=SurfNetProvider&returnUrl="+window.REACT_APP_OIDC_CALLBACK_URL;
    
    // redux
    const dispatch = useDispatch();

    const [userInfo, updateUserInfo] = useState(new loginInfo());

    const [resetPassword, toggleResetPassword] = useState(false);
    const [loadingResetPassword, toggleLoadingResetPassword] = useState(false);

    // input
    const [emailError, updateEmailError] = useState(new errorClass({
        errorCode: SUCCESS
    }));
    const [passwordError, updatePasswordError] = useState(new errorClass({
        errorCode: SUCCESS
    }));

    // submit
    const [loading, toggleLoading] = useState(false);

    const submit = async(e) => {

        // start loading
        toggleLoading(true);

        e.preventDefault();

        let validInput = true;

        // validate mail
        const mailValid = validateEmail(userInfo.email);

        const emailErrorTemp = convertError({
            errorCode: mailValid,
            updateLocally: updateEmailError,
            customErrors: emailErrors
        });

        if (!emailErrorTemp) {
            validInput = false;
        } else {
            if (emailErrorTemp.errorCode !== SUCCESS) {
                // update global error list
                dispatch(globalErrorsActions.addError(emailErrorTemp));
                validInput = false;
            }
        }

        // validate passward
        const passwordValid = validatePassword(userInfo.password);

        const passwordErrorTemp = convertError({
            errorCode: passwordValid,
            updateLocally: updatePasswordError,
            customErrors: passwordErrors
        });

        if (!passwordErrorTemp) {
            validInput = false;
        } else {
            if (passwordErrorTemp.errorCode !== SUCCESS) {
                // update global error list
                dispatch(globalErrorsActions.addError(passwordErrorTemp));
                validInput = false;
            }
        }

        if (validInput) {
            // send information to back-end
            const authResponse = await authApi.login(userInfo);

            // clear errors
            dispatch(globalErrorsActions.emptyErrorList());

            // nothing returned - something weard happend
            if (!authResponse) {

                dispatch(globalErrorsActions.addError(
                    convertError({
                        errorCode: INTERNAL_ERROR,
                        updateLocally: null,
                        customErrors: null
                    })
                ));
            }

            // success returned, update with 200 & update login
            if (authResponse.status === SUCCESS) {

                // log user in
                dispatch(userActions.login(authResponse.payload));

                // display success
                dispatch(globalErrorsActions.addError(
                    convertError({
                        errorCode: authResponse.status,
                        updateLocally: null,
                        customErrors: authUserErrors
                    }))
                );

                props.toggleShow(false);

            } else {

                // show auth errors locally
                if (authResponse.status === NOT_FOUND) {
                    convertError({
                        errorCode: authResponse.status,
                        updateLocally: updateEmailError,
                        customErrors: emailErrors
                    });
                } else if (authResponse.status === UNAUTHORIZED) {
                    convertError({
                        errorCode: authResponse.status,
                        updateLocally: updatePasswordError,
                        customErrors: passwordErrors
                    });
                }

                // show auth errors publicly
                dispatch(globalErrorsActions.addError(
                    convertError({
                        errorCode: authResponse.status,
                        updateLocally: null,
                        customErrors: authUserErrors
                    })
                ));
            }

        } else {
            // wait 1 second to stop loading
        }

        // stop loading
        toggleLoading(false);
    }

    const handleOiDcLogin = async() => {
        const cookies = new Cookies();
        const currentPathWithQuery = window.location.pathname + window.location.search;
        cookies.set('login_url_ck', currentPathWithQuery, { path: '/' });
        window.location.href = externalLoginUrl;
    };

    const sendResetPassword = async(e) => {

        // start loading
        toggleLoadingResetPassword(true);

        e.preventDefault();

        let validInput = true;

        // validate mail
        const mailValid = validateEmail(userInfo.email);

        const emailErrorTemp = convertError({
            errorCode: mailValid,
            updateLocally: updateEmailError,
            customErrors: emailErrors
        });

        if (!emailErrorTemp) {
            validInput = false;
        } else {
            if (emailErrorTemp.errorCode !== SUCCESS) {
                // update global error list
                dispatch(globalErrorsActions.addError(emailErrorTemp));
                validInput = false;
            }
        }

        if (validInput) {
                        
            // send information to back-end
            const authResponse = await authApi.forgotPassword(userInfo);

            // clear errors
            dispatch(globalErrorsActions.emptyErrorList());

            // nothing returned - something weard happend
            if (!authResponse) {

                dispatch(globalErrorsActions.addError(
                    convertError({
                        errorCode: INTERNAL_ERROR,
                        updateLocally: null,
                        customErrors: null
                    })
                ));
            }

            // success returned, update with 200 & update login
            if (authResponse.status === SUCCESS) {

                // display success
                dispatch(globalErrorsActions.addError(
                    convertError({
                        errorCode: authResponse.status,
                        updateLocally: null,
                        customErrors: resetErrors
                    }))
                );

                toggleLoadingResetPassword(false);
                toggleResetPassword(false);

            } else {

                // show auth errors locally
                if (authResponse.status === NOT_FOUND) {
                    convertError({
                        errorCode: authResponse.status,
                        updateLocally: updateEmailError,
                        customErrors: emailErrors
                    });
                }

                // show auth errors publicly
                dispatch(globalErrorsActions.addError(
                    convertError({
                        errorCode: authResponse.status,
                        updateLocally: null,
                        customErrors: resetErrors
                    })
                ));
            }

        } else {
            // wait 1 second to stop loading
        }

        // stop loading
        toggleLoadingResetPassword(false);
    }

    return (
        <form className="login" >

            <Title
                title="INLOGGEN"
            />
            <>
                <TextInput
                    title="E-MAIL"
                    
                    valueKey="email"
                    dataClass={userInfo}

                    errorClass={emailError}
                    updateError={updateEmailError}
                />
                {
                    resetPassword ? null :
                    <PasswordInput
                        title="WACHTWOORD"
            
                        valueKey="password"
                        dataClass={userInfo}

                        errorClass={passwordError}
                        updateError={updatePasswordError}
                    />
                }

                {
                    resetPassword ? 
                    
                    <div className="submit">{
                        loadingResetPassword ? <Loader /> :
                        
                        <Submit
                            value="VERSTUREN"
                            onClick={sendResetPassword}
                        />
                    }</div>
                    
                    :
                    
                    <div className="submit">{
                            loading ? <Loader /> :
                            
                            <Submit
                                value="INLOGGEN"
                                onClick={submit}
                            />
                    }</div>
                }
                <div 
                    className="forgotPassword"
                    onClick={() => toggleResetPassword((value) => !value)}
                >{
                    resetPassword ? 
                    "Inloggen"
                    :
                    "Wachtwoord vergeten"
                }</div>
            </>

            {
                handleEnvironment() === UVA_ENV ?
                <>
                    <div
                        className="forgotPassword"
                        onClick={handleOiDcLogin}
                    >
                    <img className="oidc-logo" src="https://wiki.surfnet.nl/download/attachments/327682/atl.site.logo?version=3&amp;modificationDate=1572364724127&amp;api=v2" alt="SURF Wiki" ></img>Login with SURFnet</div>
                </> : null
            }
            
        </form>
    )

}

export default Login;
